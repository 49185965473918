.email-container {
  display: flex;

  .destination-email {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .zip-file {
    display: flex;
    padding-left: 16px;
    align-items: center;
    justify-content: space-between;
  }

  .pdf-file {
    display: flex;
    margin-left: 16px;
    align-items: center;
    justify-content: space-between;
  }

  .csv-file {
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between;
  }
}
